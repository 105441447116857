// src/components/loading_overlay.js
import React from 'react';
import ReactDOM from 'react-dom';

const style_overlay_background = {
	position: 'fixed',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	backgroundColor: 'rgba(0, 0, 0, 0.5)',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	zIndex: 1000,
};

const style_overlay_spinner = {
	border: '8px solid var(--color_blue)', // Blauer Kreis
	borderTop: '8px solid var(--color_pink)', // Pinker Kreis
	borderRadius: '50%',
	width: '60px',
	height: '60px',
	animation: 'spin 1s linear infinite', // Drehender Kringel
};

const keyframes = `
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
`;

// Füge die Keyframes zu einem Style-Tag im Dokument hinzu
const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = keyframes;
document.head.appendChild(styleSheet);

export default () => {
	return ReactDOM.createPortal(
		<div style={style_overlay_background}>
			<div style={style_overlay_spinner}></div>
		</div>,
		document.getElementById('root-overlay')
	);
};
