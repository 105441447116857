import React from 'react';

export default () => {
	return (
		<div>
			<h1>Leaderboard</h1>
			<p>Waddup</p>
		</div>
	);
};
